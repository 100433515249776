import React, { useState, useEffect } from 'react'
import './ViewProgramFeedback.scss';
import { useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import AppContainer from '../../../components/AppContainer/AppContainer';
import { useSelector, useDispatch } from 'react-redux';
import { viewProgramFeedbackDetailsAction } from '../../../redux/actions/ReportAction/ProgramFeedbackAction/ProgramFeedbackAction';

const ViewProgramFeedback = (props) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [viewProgramFeedbackMainData, setViewProgramFeedbackMainData] = useState('');
    
    const {loading} = useSelector(state => state.viewProgramFeedback);
    const viewProgramFeedbackDataRes = useSelector(state => state.viewProgramFeedback?.viewProgramFeedback?.data);

    const { data } = location.state;
    const { history } = props;

    useEffect(() => {
        const sendRequest = {
            "user_program_id": data.user_program_id,
            'user_id': data.user_id
        };
        dispatch(viewProgramFeedbackDetailsAction(sendRequest));
    }, []);

    useEffect(() => {
        setViewProgramFeedbackMainData(viewProgramFeedbackDataRes && viewProgramFeedbackDataRes);
    }, [viewProgramFeedbackDataRes])


    return (
        <>
            <AppContainer history={history}>
                <div className="event-content">
                    <div className='ViewProgramsFeedback'>
                        {   
                            loading && 
                            <div className='loader'>
                                <CircularProgress sx={{'color': '#74613C'}}/>
                            </div>
                        }
                        {!loading && viewProgramFeedbackDataRes && viewProgramFeedbackMainData && <div className="card">
                            <div className="card-header">
                                <div className='d-flex align-items-center mb-2'><h6 className='programTitle'>PROGRAM: </h6>{data.title}</div>
                                <div className='d-flex align-items-center'><h6 className='programTitle'>GUEST NAME: </h6>{data.guest_name}</div>
                            </div>
                            <div className="separator"></div>
                            <div className="card-body">
                                {
                                    viewProgramFeedbackMainData?.map((section, index)=>{
                                        return(
                                            <div key={index}>
                                                {
                                                    section?.questions?.map((question, index1)=>{
                                                        return(
                                                            <div className='question-box' key={`${index+index1}`}>
                                                                <div className='question'> <span className='question-number'>{question?.question_position}.</span>  {question?.question_title} </div>
                                                                <div> 
                                                                    {
                                                                        question?.question_options?.map((subQuestion,index2)=>{
                                                                            return(
                                                                                <div className='subquestion-box' key={`${index+index1+index2}`}>
                                                                                    {subQuestion?.option_tag_name && <div className='mb-2'> {subQuestion?.option_tag_name} </div>}
                                                                                    
                                                                                    <div className='answer-box'>
                                                                                        {
                                                                                            subQuestion?.user_input === '1' &&
                                                                                                <div className='text-answer'>{question?.answers?.[index2]?.user_input || '-'}</div>
                                                                                                
                                                                                        }
                                                                                        <div className='rating-answer'>
                                                                                                {
                                                                                                    subQuestion?.max_rating?
                                                                                                    <div className='d-flex'>
                                                                                                        {
                                                                                                            Array.apply(null, {length: parseInt(subQuestion?.max_rating)}).map((i, index3)=>{
                                                                                                                return(
                                                                                                                    <div className='radio-box' key={`${index+index1+index2+index3}`}>
                                                                                                                        <input type='radio' disabled={question?.answers?.[index2]?.rating != index3+1} checked={question?.answers?.[index2]?.rating == index3+1} onChange={()=>{}}/> &nbsp;
                                                                                                                        <span>{index3+1}</span>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </div>
                                                                                                    :null
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    } 
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }                                
                            </div>
                        </div >}
                    </div>
                </div >
            </AppContainer >
        </>
    )
}

export default ViewProgramFeedback
