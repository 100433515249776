import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import ArrowBackIosNewSharpIcon from '@mui/icons-material/ArrowBackIosNewSharp';
import { visuallyHidden } from '@mui/utils';
import Divider from '@mui/material/Divider';
import { createNotification } from '../../../Config/NotificationToast';
import styled from '@emotion/styled';
import Select from "react-select";
import { FormGroup, Label, } from "reactstrap";
import { deleteGuest, viewGuestUsers, } from '../../../redux/actions/UserManagementAction/GuestUserAction/GuestUserAction';
import { updateIsActiveStatus } from '../../../redux/actions/MasterDataAction/ProgramAction/programActions';
import * as routes from "../../../Router/RoutesURL";
import {
    stableSort,
    getComparator,
    rowOptions,
    options,
    getPermissionByAppName,
    validateDate,
} from '../../../utils/Helper'
import { useSelector, useDispatch } from 'react-redux';
import './GuestUser.scss';
import { CircularProgress } from '@mui/material';
import Delete from "../../../assets/images/CommonComponent/trash.svg";
import crossIcon from "../../../assets/images/CommonComponent/CrossIcon.svg";
import { Modal } from 'react-bootstrap';
import { guestType } from '../../../Config/Constant';
import moment from 'moment';
import { format } from 'date-fns';
import calendarIcon from '../../../assets/images/CommonComponent/calendarIcon.png';
import DatePicker from 'react-datepicker';

const MainHeading = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
}))


const headCells = [
    {
        id: 'first_name',
        disablePadding: true,
        label: 'FIRST NAME',
    },
    {
        id: 'last_name',
        disablePadding: false,
        label: 'LAST NAME',
    },
    {
        id: 'phone_number',
        disablePadding: false,
        label: 'MOBILE NO.',
    },
    {
        id: 'email_id',
        disablePadding: false,
        label: 'EMAIL ADDRESS',
    },
    {
        id: 'title',
        disablePadding: false,
        label: 'PROGRAM',
    },
    {
        id: 'check_in',
        disablePadding: false,
        label: 'START',
    },
    {
        id: 'check_out',
        disablePadding: false,
        label: 'END',
    },
    {
        id: 'is_active',
        disablePadding: false,
        label: 'STATUS',
    },
    {
        id: 'action',
        disablePadding: false,
        label: 'ACTION',

    },
];

const EnhancedTableHead=(props)=> {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align='left'
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            hideSortIcon={headCell.id === 'action' ? true : false}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar=(props)=> {
    const { numSelected, pageNos, rowsPerPageNos, showLoader, setShowLoader, activeTab } = props;
    const dispatch = useDispatch();
    const filterData = JSON.parse(localStorage.getItem('filterGuestUsers'))
    const [searchText, setSearchText] = useState(filterData?.filter_text  || '');
    const [maxDuration, setMaxDuration] = useState(filterData?.end_date || '');
    const [minDuration, setMinDuration] = useState(filterData?.start_date || '');
    const [programListing, setProgramListing] = useState([]);

    const [programID, setProgramID] = useState(filterData?.program || '');
    const [selectedProgramTitle, setSelectedProgramTitle] = useState('');

    const programListingData = useSelector(state => state.viewGuestUsers?.viewGuestsList?.data?.program_data);


    useEffect(() => {
        if (programListingData) {
            let data = programListingData.map((item) => {
                if(programID===item?.id)
                    setSelectedProgramTitle({
                        "label": item.title,
                        "value": item.title,
                        "key": programID
                    })

                return{
                ...item,
                value: item.title,
            }});
            data = [{
                "title": 'All',
                "value": 'All',
                "id": ''
            }, ...data]
            setProgramListing(data);
        } else {
            setProgramListing([]);
        }
    }, [programListingData]);

    useEffect(() => {
        if (activeTab == "GuestUser") {
            const localData = JSON.parse(localStorage.getItem("filterGuestUsers"))
            if(!localData?.filter_text){
                setSearchText('')
            }
            if(!localData?.start_date){
                setMinDuration('')
            }
            if(!localData?.end_date){
                setMaxDuration('')
            }
            if(!localData?.program){
                setProgramID('')
                setSelectedProgramTitle('')
            }
        }
    }, [activeTab]);


    useEffect(() => {
        let filtersValues = { "filter_text": searchText, "start_date": minDuration, "end_date": maxDuration, "program": programID, };
        localStorage.setItem("filterGuestUsers", JSON.stringify(filtersValues))
    }, [searchText, minDuration, maxDuration, programID]);
    

    const handleChangeSearch = (e) => {
        setSearchText(e.target.value)
        const sendRequest = {
            "page_no": pageNos,
            "page_size": rowsPerPageNos,
            'filter_text': e.target.value,
            "start_date": minDuration,
            "end_date": maxDuration,
            "program": programID,
            "role_id": 1,
            "type": guestType.ENROLLED
        };
        setShowLoader(true)
        dispatch(viewGuestUsers(sendRequest));
    }

    const changeFromDate = (val) => {
        const formattedDate = val ? format(val, "yyyy-MM-dd") : '';
        setMinDuration(formattedDate)
        const sendRequest = {
            "page_no": pageNos,
            "page_size": rowsPerPageNos,
            'filter_text': searchText,
            "start_date": formattedDate,
            "end_date": maxDuration,
            "program": programID,
            "role_id": 1,
            "type": guestType.ENROLLED
        };
        setShowLoader(true)
        dispatch(viewGuestUsers(sendRequest));
    }

    const changeToDate = (val) => {
        const formattedDate = val ? format(val, "yyyy-MM-dd") : '';
        setMaxDuration(formattedDate)
        const sendRequest = {
            "page_no": pageNos,
            "page_size": rowsPerPageNos,
            'filter_text': searchText,
            "start_date": minDuration,
            "end_date": formattedDate,
            "program": programID,
            "role_id": 1,
            "type": guestType.ENROLLED
        };
        setShowLoader(true)
        dispatch(viewGuestUsers(sendRequest));
    }

    const handleChangeLevel = (e) => {
        setProgramID(e.key)
        setSelectedProgramTitle(e)
        const sendRequest = {
            "page_no": pageNos,
            "page_size": rowsPerPageNos,
            'filter_text': searchText,
            "start_date": minDuration,
            "end_date": maxDuration,
            "program": e.key,
            "role_id": 1,
            "type": guestType.ENROLLED
        };
        setShowLoader(true)
        dispatch(viewGuestUsers(sendRequest));
    }



    return (

        <>
            <Toolbar sx={{
                pl: { sm: 2 },
                pt: { sm: 2 },
                pb: { sm: 2 },
                pr: { xs: 1, sm: 1 }
            }}
            >
                <div className='d-flex justify-content-between align-items-center w-100'>
                    <div>
                        <MainHeading
                            sx={{ flex: '1 1 100%', }}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                        >
                            Guest List
                        </MainHeading>
                    </div>
                    <div className='GuestUserLevelDiv'>
                        <FormGroup className="FormGroup has-float-label class-menu-dropdown ">
                            <Label>Program:</Label>
                            <Select
                                className="react-select"
                                classNamePrefix="react-select"
                                value={selectedProgramTitle}
                                options={programListing?.map((guest) => {
                                    return {

                                        label: guest.title,
                                        value: guest.title,
                                        key: guest.id
                                    };
                                })}
                                onChange={handleChangeLevel}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#F5F5F5',
                                        primary: '#74613C',
                                    },
                                })}
                            />

                        </FormGroup>
                        <div className='DurationDiv d-flex align-items-center'>
                            <Label>Date:</Label>
                            <div className='dateBlock'>
                                {minDuration == "" ?
                                    <div className="dayDateTextDiv" ><p className="dayDateTextPlaceholder">Start</p></div>
                                    : <div className="dayDateTextDiv" >
                                        <p className="dayDateText d-flex align-items-center">
                                            {(moment(minDuration, "YYYY-MM-DD").format('DD/MM/YYYY'))} 
                                            {minDuration && <img src={crossIcon} className="ml-2 mt-1" alt="Avatar" width="14px" height="16px" onClick={()=>changeFromDate('')} />}
                                        </p>

                                    </div>
                                }
                                <div className='datePickerDiv'>
                                    <DatePicker
                                        selected={validateDate(minDuration) ? new Date(moment(minDuration, "YYYY-MM-DD").format('MM-DD-YYYY')) : new Date()}
                                        maxDate={new Date()}
                                        onChange={date => { changeFromDate(date) }}
                                        popperPlacement={"bottom"}
                                        customInput={
                                            <img src={calendarIcon} className="calIcon" alt="" />
                                        }
                                        showYearDropdown={true}
                                        showMonthDropdown={true}
                                    />
                                </div>
                            </div>
                            <div className='mx-2'>to</div>
                            <div className='dateBlock'>
                                {maxDuration == "" ?
                                    <div className="dayDateTextDiv" ><p className="dayDateTextPlaceholder">End</p></div>
                                    : 
                                    <div className="dayDateTextDiv" >
                                        <p className="dayDateText d-flex ">
                                            {(moment(maxDuration, "YYYY-MM-DD").format('DD/MM/YYYY'))} 
                                            {maxDuration && <img src={crossIcon} className="ml-2 mt-1" alt="Avatar" width="14px" height="16px" onClick={()=>changeToDate('')} />}
                                        </p>
                                    </div>
                                }
                                <div className='datePickerDiv'>
                                    <DatePicker
                                        selected={validateDate(maxDuration) ? new Date(moment(maxDuration, "YYYY-MM-DD").format('MM-DD-YYYY')) : new Date()}
                                        minDate={new Date((moment(minDuration, "YYYY-MM-DD").add(1, 'days')).format('MM-DD-YYYY'))}
                                        onChange={date => { changeToDate(date) }}
                                        popperPlacement={"bottom"}
                                        customInput={
                                            <img src={calendarIcon} className="calIcon" alt="" />
                                        }
                                        showYearDropdown={true}
                                        showMonthDropdown={true}
                                        disabled={!minDuration}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="separator"></div>

                        <div className="mr-sm-2 searchDiv class-search ">
                            <input
                                value={searchText}
                                className="form-control mr-sm-2 class-search"
                                type="search"
                                placeholder=" Search Keyword"
                                aria-label="Search"
                                onChange={handleChangeSearch}
                            ></input>
                        </div>
                    </div>
                </div>
            </Toolbar>
            <Divider />
        </>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const GuestUserTable = (props) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [viewGuestListMainData, setViewGuestListMainData] = useState('');
    const [showNext, setShowNext] = useState(false);
    const [showPrev, setShowPrev] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const navigate = useNavigate()
    const {loading} = useSelector(state => state.viewGuestUsers);
    const viewGuestListDat = useSelector(state => state.viewGuestUsers);
    const viewGuestListDataRes = useSelector(state => state.viewGuestUsers.viewGuestsList);
    const btnPrev = ["btnPrev", showPrev ? "" : "opacityBtns"]
    const btnNext = ["btnNext", showNext ? "" : "opacityBtns"]
    const dispatch = useDispatch();
    const { permission, activeTab } = props;
    const [showConfirm, setShowConfirm] = useState(false);
    const [id, setId] = useState('');

    useEffect(() => {
        if (activeTab == "GuestUser") {
            const localData = JSON.parse(localStorage.getItem("filterGuestUsers"))
            let sendRequest = {
                "page_no": 1,
                "page_size": 10,
                'filter_text': localData?.filter_text || '',
                "start_date": localData?.start_date || '',
                "end_date": localData?.end_date || '',
                "program": localData?.program || '',
                "role_id": 1,
                "type": guestType.ENROLLED
            };
            dispatch(viewGuestUsers(sendRequest));
        }
    }, [activeTab]);

    useEffect(()=>{
        if(loading && !viewGuestListDataRes?.data?.guest_users && !viewGuestListDat.error){
            setShowLoader(true)
        }else if(!loading)
            setShowLoader(false)

    }, [loading])

    useEffect(() => {
        if (viewGuestListDat.error !== "No data available to show for guests users") {
            setViewGuestListMainData(viewGuestListDataRes && viewGuestListDataRes?.data?.guest_users);
            if (viewGuestListDataRes?.data?.current_page_no === 1) {
                setShowPrev(false)
            } else {
                setShowPrev(true)
            }

            if (viewGuestListDataRes?.data?.current_page_no === viewGuestListDataRes?.data?.total_pages) {
                setShowNext(false)
            } else {
                setShowNext(true)
            }
        } else {
            setViewGuestListMainData("");
        }
    }, [viewGuestListDataRes, viewGuestListDat])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = viewGuestListDataRes && viewGuestListDataRes.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePageNext = (newPage) => {
        setShowLoader(true)
        setPage(newPage);
        const filterdata = JSON.parse(localStorage.getItem("filterGuestUsers"));
        const sendRequest = {
            "page_no": viewGuestListDataRes?.data?.current_page_no + 1,
            "page_size": rowsPerPage,
            'filter_text': filterdata?.filter_text,
            "start_date": filterdata?.start_date,
            "end_date": filterdata?.end_date,
            "program": filterdata?.program,
            "role_id": 1,
            "type": guestType.ENROLLED
        };
        dispatch(viewGuestUsers(sendRequest));
    };
    const handleChangePagePrev = (newPage) => {
        setShowLoader(true)
        setPage(newPage);
        const filterdata = JSON.parse(localStorage.getItem("filterGuestUsers"));
        const sendRequest = {
            "page_no": viewGuestListDataRes?.data?.current_page_no - 1,
            "page_size": rowsPerPage,
            'filter_text': filterdata?.filter_text,
            "start_date": filterdata?.start_date,
            "end_date": filterdata?.end_date,
            "program": filterdata?.program,
            "role_id": 1,
            "type": guestType.ENROLLED
        };

        dispatch(viewGuestUsers(sendRequest));
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.value);
        setPage(page);
        const filterdata = JSON.parse(localStorage.getItem("filterGuestUsers"));
        const sendRequest = {
            "page_no": 1,
            "page_size": event.value,
            'filter_text': filterdata?.filter_text,
            "start_date": filterdata?.start_date,
            "end_date": filterdata?.end_date,
            "program": filterdata?.program,
            "role_id": 1,
            "type": guestType.ENROLLED
        };
        dispatch(viewGuestUsers(sendRequest));
    };


    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - viewGuestListDataRes && viewGuestListDataRes?.length) : 0;


    const handleChangeViewPro = (selectedUserId, userStatus) => {
        if (userStatus === 1) {
            navigate(routes.VIEW_USER, { state: { selectedUserId, userStatus, permission } })
        }
    };


    const handleChangeOption = (e, id) => {
        if (permission === "write") {
            if (e.value === "Active") {
                viewGuestListMainData.find(v => v.id === id).is_active = 1;
            } else {
                viewGuestListMainData.find(v => v.id === id).is_active = 0;
            }
            setViewGuestListMainData(viewGuestListMainData)
            const sendRequest = {
                "feature_type": "guest",
                "id": id,
                "status": e.value === "Active" ? 1 : 0
            };
            dispatch(updateIsActiveStatus(sendRequest));
        } else {
            createNotification('warning', "Access Restricted");
        }
    }

    const handleChangeEditUser = (selectedUserId, userStatus) => {
        if (permission === "write") {
            if (userStatus === 1) {
                navigate(routes.EDIT_USER, { state: { selectedUserId } });
            }
        } else {
            createNotification('warning', "Access Restricted");
        }
    };


    const handleDelete = (id) => {
        if (permission === "write") {
            setId(id)
            setShowConfirm(true);
        } else {
            createNotification('warning', "Access Restricted");
        }
    };

    const handleDeleteGuest = () => {
        dispatch(deleteGuest({id}))
        setShowConfirm(false);
        setTimeout(() => {
            const localData = JSON.parse(localStorage.getItem("filterGuestUsers"))
            let sendRequest = {
                "page_no": viewGuestListDataRes?.data?.guest_users?.length>1 ? viewGuestListDataRes?.data?.current_page_no : (viewGuestListDataRes?.data?.current_page_no-1>0 ? viewGuestListDataRes?.data?.current_page_no : 1),
                "page_size": viewGuestListDataRes?.data?.page_size || 10,
                'filter_text': localData?.filter_text || '',
                "start_date": localData?.start_date || '',
                "end_date": localData?.end_date || '',
                "program": localData?.program || '',
                "role_id": 1,
                "type": guestType.ENROLLED
            };
            dispatch(viewGuestUsers(sendRequest));
        }, 1000);
    };

    const cancelWarnModal = () => {
        setShowConfirm(false);
    }

    return (
        <>
            <Box sx={{ width: '100%' }} className="GuestUserBox">
                <Paper sx={{ width: '100%', mb: 2, borderRadius: 2 }}>
                    <EnhancedTableToolbar numSelected={selected.length} pageNos={page}
                        activeTab={activeTab}
                        showLoader={showLoader}
                        setShowLoader={setShowLoader}
                        rowsPerPageNos={rowsPerPage} />
                    {
                        showLoader ?
                        <div className='w-100 py-5 text-center'>
                            <CircularProgress sx={{'color': '#74613C'}}/>
                        </div>
                        :
                            viewGuestListMainData?.length > 0 && <TableContainer sx={{ pt: 1, pr: 3, pb: 3, pl: 3 }}>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size='small'
                            >
                                <EnhancedTableHead
                                    numSelected={selected.length}
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={handleSelectAllClick}
                                    onRequestSort={handleRequestSort}
                                    rowCount={viewGuestListDataRes && viewGuestListDataRes?.length}
                                />
                                <TableBody>
                                    {
                                        stableSort(viewGuestListMainData, getComparator(order, orderBy))
                                            .slice((rowsPerPage * (page - 1)), (rowsPerPage * (page) + rowsPerPage))
                                            .map((row, index) => {
    
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    <TableRow hover onClick={(event) => handleClick(event, row.firstName)} role="checkbox"
    
                                                        tabIndex={-1}
                                                        key={row.id}
    
                                                    >
                                                        <TableCell component="th" id={labelId} scope="row" padding="none" sx={{ color: row.is_active == 1 ? '#000000' : "#AAAAAA" }}>{row.first_name}</TableCell>
                                                        <TableCell align="left" sx={{ color: row.is_active == 1 ? '#000000' : "#AAAAAA" }}>{row.last_name}</TableCell>
                                                        <TableCell align="left" sx={{ color: row.is_active == 1 ? '#000000' : "#AAAAAA" }}>{row.phone_number}</TableCell>
                                                        <TableCell align="left" sx={{ color: row.is_active == 1 ? '#000000' : "#AAAAAA" , textTransform: "lowercase"}}>{row.email_id}</TableCell>
                                                        <TableCell align="left" sx={{ color: row.is_active == 1 ? '#000000' : "#AAAAAA" }}>{row.title}</TableCell>
                                                        <TableCell align="left" sx={{ color: row.is_active == 1 ? '#000000' : "#AAAAAA" }}>{row.check_in}</TableCell>
                                                        <TableCell align="left" sx={{ color: row.is_active == 1 ? '#000000' : "#AAAAAA" }}>{row.check_out}</TableCell>
                                                        <TableCell align="left" sx={{ color: row.is_active == 1 ? '#000000' : "#AAAAAA" }}
    
                                                        >
                                                            <Select
    
                                                                className="react-select"
                                                                classNamePrefix="react-select"
                                                                onChange={(e) => { handleChangeOption(e, row.id) }}
                                                                value={row.is_active === 1 ? { label: "Active", value: "Active" } : { label: "Inactive", value: "Inactive" }}
                                                                options={options}
                                                                isSearchable={false}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    isFocused: "#74613C",
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#F5F5F5',
                                                                        primary: '#f6f4f0',
                                                                    },
                                                                })}
                                                                getOptionLabel={(row) => (
                                                                    <><span className={row.label === "Active" ? "ActiveClass" : "InactiveClass"}>
                                                                        {row.label}
                                                                    </span>
                                                                        {row.status === 1 && (
                                                                            <div
    
                                                                            ></div>
                                                                        )}
                                                                    </>
                                                                )}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left" className='View'> <ul className="list-group d-flex flex-row">
                                                            <li className={row.is_active == 1 ? 'list-group-item ViewEditClick p-0 ' : "list-group-item ViewEditNotClick p-0"}
                                                                onClick={() => { handleChangeViewPro(row.id, row.is_active) }}><i className="fas fa-eye mr-1"></i> View</li>
                                                            {
                                                                getPermissionByAppName("Guest User Management") === "write" &&
                                                                <>
                                                                    <li className={row.is_active == 1 ? 'list-group-item ViewEditClick p-0 ' : "list-group-item ViewEditNotClick p-0"}
                                                                        onClick={() => { handleChangeEditUser(row.id, row.is_active) }}><i className="fa fa-pen mr-1"></i> Edit</li>
                                                                    <li className="list-group-item Delete p-0" onClick={() => { handleDelete(row.id) }}><img src={Delete} className="mr-1 mb-1" alt="Avatar" width="14px" height="16px" /> Delete</li>
                                                                </>
                                                            }
    
                                                        </ul></TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    {emptyRows > 0 && (
                                        <TableRow
    
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </Paper>
                {
                    viewGuestListMainData
                    &&
                    <div className='paginationBlock'>
                        <div className='textData'><p>Items per page: </p></div>
                        <FormGroup className="FormGroup has-float-label rowsSelect class-menu-dropdown ">
                            <Select
                                placeholder={rowsPerPage}
                                className="react-select"
                                classNamePrefix="react-select"
                                value={rowsPerPage}
                                options={rowOptions}
                                isSearchable={false}
                                onChange={handleChangeRowsPerPage}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#F5F5F5',
                                        primary: '#74613C',
                                    },
                                })}
                                menuPlacement="top"
                                menuPosition="fixed"
                                styles={{
                                    menu: (base) => ({
                                        ...base,
                                        top: 'auto',
                                        bottom: 0,
                                    }),
                                }}
                            />
                        </FormGroup>
                        {viewGuestListDataRes?.data && <p className='endText'>   {viewGuestListDataRes?.data?.start} - {viewGuestListDataRes?.data?.end} of {viewGuestListDataRes?.data?.total}</p>}
                        <p>
                            <button className={btnPrev.join(' ')} onClick={() => { handleChangePagePrev(page) }}>
                                <ArrowBackIosNewSharpIcon className='iconBtn' />
                            </button>
                            <button className={btnNext.join(' ')} onClick={() => { handleChangePageNext(page) }}>
                                <ArrowForwardIosSharpIcon className='iconBtn' />
                            </button></p>
                    </div>
                }
            </Box>
        
        <Modal centered className="guestUser-modal-warning br-8" show={showConfirm} onHide={() => { setShowConfirm(false) }}>
                <Modal.Header>
                    <div className="modalText-guest pb-4">Are you sure you want to delete this guest account ?</div>
                </Modal.Header>
                <Modal.Body>
                    <div className="">
                        <label htmlFor="exampleFormControlInput1" className="form-label DeleteDesc-guest">
                        This will remove guest's online access to all your wellness programme details, sessions, consultant recommendations and other data.
                        </label>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-success greenButton mr-3" onClick={cancelWarnModal}>
                        Cancel</button>
                    <button type="button" className="btn btn-danger redButton " onClick={handleDeleteGuest}>
                        Delete</button>
                </Modal.Footer>
            </Modal>
        </>
        );
}
export default GuestUserTable;